import * as React from 'react';
import { FirebaseAppContext } from '../../Context/FirebaseAppContext';
import { getFirestore, collection, query, where, getDocs, onSnapshot, addDoc, doc } from "firebase/firestore"; 
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { STRIPE_ROLES, INTERVALS } from '../../js/constants';
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';

const Subscribe = (props) => {
    const firebaseApp = React.useContext(FirebaseAppContext);
    const { auth } = React.useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const [priceData, setPriceData] = React.useState(null);
    const [selectedPrice, setSelectedPrice] = React.useState(INTERVALS.YEAR);
    const [subscribeInProgress, setSubscribeInProgress] = React.useState(false);
    const {setSnackbar} = React.useContext(SnackbarContext);

    React.useEffect(()=>{
        getPrices();
    }, []);
  
    const getPrices = async () => {
        const productsQuery = query(collection(db, 'products'), where('active', '==', true));
        const productsSnapshot = await getDocs(productsQuery);

        productsSnapshot.forEach(async(product) => {
            const pricesQuery = query(collection(db, `products/${product.id}/prices`), where('active', '==', true));
            const pricesSnapshot = await getDocs(pricesQuery);

            const prices = [];
            pricesSnapshot.forEach((price) => {
                prices.push({id: price.id, data: price.data()});
            });
            setPriceData(prices);
        });
    };

    const formatPrice = (price) => {
        price /= Math.pow(10, 2);
        return price.toFixed(2);
    };

    const subscribe = async () => {
        const {currentUser} = auth;
        const {id} = priceData.filter((price)=>{return price.data.interval === selectedPrice})[0];

        const docRef = await addDoc(collection(db, `customers/${currentUser.uid}/checkout_sessions`), {
            price: id,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
        });

        onSnapshot(doc(db, `customers/${currentUser.uid}/checkout_sessions`, docRef.id), (doc) => {
            const { error, url } = doc.data();
            if (error) {
                setSubscribeInProgress(false);
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                window.location.assign(url);
            }
        });        
    };

    const onSubscribeClick = () => {
        setSubscribeInProgress(true);
        setSnackbar({
            text: "Redirecting you to secure payment",
            severity: "success",
        });
        subscribe();
    };

    const PriceCardsAndButton = () => {
        const monthPrice = priceData.find(price=>price.data.interval === INTERVALS.MONTH);
        const yearPrice = priceData.find(price=>price.data.interval === INTERVALS.YEAR);
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography gutterBottom sx={{ fontSize: { xs: "22px", md: "30px" }, }}>
                    Your next art project is a click away!
                </Typography>
                
                <div style={{display: "flex", margin:"20px"}}>
                    <PriceCard {...yearPrice}/>
                    <PriceCard {...monthPrice}/>
                </div>

                <Button
                    variant="contained"
                    size="large"
                    color="success"
                    disabled={subscribeInProgress}
                    onClick={onSubscribeClick}
                    endIcon={subscribeInProgress && <CircularProgress color="inherit" style={{height: "20px", width: "20px"}} />}>
                    {subscribeInProgress ? "Please wait" : "Continue"}
                </Button>
                <Typography gutterBottom variant="caption" component="div">
                    You can cancel anytime
                </Typography>

                {props.children}
            </div>
        );
    }

    const PriceCard = (props) => {
        const {unit_amount, currency, interval} = props.data;

        const title = interval === INTERVALS.MONTH ? "MOST POPULAR" : "BEST VALUE";
        const monthCount = interval === INTERVALS.MONTH ? 1 : 12;
        const monthPrice = interval === INTERVALS.MONTH ? unit_amount : unit_amount / 12;
        
        const color = interval === selectedPrice ? "green" : "grey";

        return (
            <>
                <div style={{height: "235px", width: "170px", borderStyle: "solid", borderWidth: "3px", borderRadius: "25px", borderColor: color, cursor: "pointer", marginLeft: "10px", marginRight: "10px"}} onClick={()=>setSelectedPrice(interval)}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{backgroundColor: color, color: "white", width: "100%", height: "25px", lineHeight: "25px", textAlign: "center", borderRadius: "20px 20px 0px 0px"}}>
                            {title}
                        </div>
                        <div style={{height: "8 0px", lineHeight: "80px", textAlign: "center", fontSize: "50px", fontWeight: "600"}}>
                            {monthCount}
                        </div>
                        <div style={{height: "20px", lineHeight: "20px", textAlign: "center", fontSize: "20px", fontWeight: "bolder"}}>
                            {INTERVALS.MONTH}{interval === INTERVALS.MONTH ? "" : "s"}
                        </div>
                        <div style={{height: "30px", lineHeight: "30px", color: "grey"}}>
                            ${formatPrice(monthPrice)}{"/mo"}
                        </div>

                        {interval === INTERVALS.MONTH ? 
                        <div style={{borderStyle: "solid", borderWidth: "1px 0px 0px 0px", borderColor: "grey", width: "80%", height: "20px", marginTop: "20px"}} /> : 
                        <div style={{height: "41px", lineHeight:"41px", fontSize: "20px",fontWeight: "700", color: "green"}}>
                            SAVE 16%
                        </div>}

                        <div style={{display: "flex", alignItems: "baseline"}}>
                            <div style={{fontSize: "20px",fontWeight: "bolder"}}>
                                ${formatPrice(unit_amount)}
                            </div>
                            <div style={{fontSize: "12px",fontWeight: "bold"}}>
                                {String(currency).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
  
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", padding: "16px"}}>
            {priceData ? <PriceCardsAndButton /> : <CircularProgress />}
        </div>
    );
};

const SubscribedCheck = (props) => {
    const states = {
        "LOADING": 0, "NOT_SUBSCRIBED": 1, "SUBSCRIBED": 2
    };
    
    const {subscribed, notSubscribed} = props;
    const [view, setView] = React.useState(states.LOADING);
    const { auth } = React.useContext(AuthContext);

    const getView = () => {
        switch (view) {
            case states.LOADING:
            default:
                return <CircularProgress />
            case states.NOT_SUBSCRIBED:
                return notSubscribed
            case states.SUBSCRIBED:
                return subscribed
        }
    };

    React.useEffect(() => {
        async function getCustomClaimRole() {
            await auth.currentUser.getIdToken(true);
            await auth.currentUser.getIdTokenResult().then((decodedToken) => {
                if (decodedToken.claims.stripeRole === STRIPE_ROLES.PAYING_USER) {
                    setView(states.SUBSCRIBED);
                } else {
                    setView(states.NOT_SUBSCRIBED);
                }
            });
        }
        getCustomClaimRole();
    }, []);
        
  return (
    getView()
  );
}

export {Subscribe, SubscribedCheck};